.btn-uninorte:disabled {
    background-color: white;
    border-color: #032855;
    color: #032855;
  }
.btn-uninorte {
    background-color: #032855;
    border-color: #032855;
    color: white;
  }

.spinner {
  z-index: 3;
}