
.dgr-select-outlined {
  --pure-material-safari-helper1: rgb(var(--pure-material-primary-rgb, 33, 150, 243));
  position: relative;
  display: inline-block;
  padding-top: 6px;
  font-family: 'Roboto', 'Segoe UI', BlinkMacSystemFont, system-ui, -apple-system;
  font-size: 16px;
  line-height: 1.5;
  width: 100%;
  margin-bottom: 5px;
}

.select-text {
  position: relative;
  font-family: inherit;
  background-color: transparent;
  width: 100%;
  padding: 10px 13px 10px;
  font-size: 16px;
  border: 1px solid rgba(0, 0, 0, 0.6);
  border-radius: 4px;

  option:checked {
    width: 100px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

/* Remove focus */

.select-text {
  &:focus {
    outline: none;
    border-color: #2f80ed;
  }
}

/* Use custom arrow */

.dgr-select-outlined .select-text {
  appearance: none;
  -webkit-appearance: none;
}

.dgr-select-outlined:after {
  position: absolute;
  top: 26px;
  right: 10px;
  /* Styling the down arrow */
  width: 0;
  height: 0;
  padding: 0;
  content: '';
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid rgba(0, 0, 0, 0.6);
  pointer-events: none;
}

/* LABEL ======================================= */

.select-label {
  color: rgba(0, 0, 0, 0.6);
  font-size: 16px;
  font-weight: normal;
  position: absolute;
  top: 16px;
  left: 14px;
  pointer-events: none;
  cursor: text;
  transition: 0.2s ease all;
}

/* active state */

.select-text:valid ~ .select-label {
  color: #2f80ed;
  top: 0;
  font-size: 75%;
  line-height: 15px;
  cursor: text;
  background-color: #ffffff;
  padding-left: 5px;
  padding-right: 5px;
  transition: 0.2s ease all;
}

.select-text:focus ~ .select-label {
  color: rgba(0, 0, 0, 0.6);
  top: 0;
  font-size: 75%;
  line-height: 15px;
  cursor: text;
  background-color: #ffffff;
  padding-left: 5px;
  padding-right: 5px;
  transition: 0.2s ease all;
}

