

.dgr-textfield-outlined {
    --pure-material-safari-helper1: rgb(var(--pure-material-primary-rgb, 33, 150, 243));
    position: relative;
    display: inline-block;
    padding-top: 6px;
    font-family: 'Roboto', 'Segoe UI', BlinkMacSystemFont, system-ui, -apple-system;
    font-size: 16px;
    line-height: 1.5;
    overflow: hidden;
    width: 100%;
    margin-bottom: 5px;
  
  
    &__error {
      > input, textarea {
        border-color: red !important;
        border-top-color: transparent !important;
        outline: none;
  
        &:not(:focus):placeholder-shown {
          border-color: red !important;
        }
  
        + span {
          height: 0px;
          background-color: #ffffff;
          color: red !important;
  
          &:after {
            border-top-color: red !important;
          }
  
          &:before {
            border-top-color: red !important;
            border-left-color: red !important;
          }
        }
  
        /* Focus */
        &:focus {
          border-color: red;
          border-top-color: transparent;
          box-shadow: inset 1px 0 red, inset -1px 0 red, inset 0 -1px red;
          outline: none;
  
          + span {
            color: red;
  
            &::before,
            &::after {
              border-top-color: red !important;
              box-shadow: inset 0 1px red;
            }
          }
        }
  
        + span {
          border-color: red;
        }
      }
  
      &:hover {
        > input {
          border-color: red;
          border-top-color: transparent;
          outline: none;
  
          + span {
            color: red;
  
            &::before,
            &::after {
              border-top-color: red;
            }
          }
  
          /* Focus */
          &:focus {
            border-color: red;
            border-top-color: transparent;
            box-shadow: inset 1px 0 red, inset -1px 0 red, inset 0 -1px red;
            outline: none;
  
            + span {
              color: red;
  
              &::before,
              &::after {
                border-top-color: red !important;
                box-shadow: inset 0 1px red;
              }
            }
          }
  
          &:not(:focus):placeholder-shown {
            border-color: red;
          }
        }
      }
    }
  
    input {
      &::-webkit-input-placeholder {
        color: transparent;
      }
  
      &::-ms-input-placeholder {
        color: transparent;
      }
  
      &::-moz-placeholder {
        color: transparent;
      }
  
      &:focus::-webkit-input-placeholder {
        color: rgba(0, 0, 0, 0.6);
        transition: all 0.2s ease;
      }
  
      &:focus::-moz-placeholder {
        color: rgba(0, 0, 0, 0.6);
        transition: all 0.2s ease;
      }
  
      &:focus::-ms-input-placeholder {
        color: rgba(0, 0, 0, 0.6);
        transition: all 0.2s ease;
      }
    }
  
    &--helper-line {
      padding-right: 16px;
      padding-left: 16px;
    }
  
    &--helper-text {
      font-size: 0.75rem;
      color: rgba(0, 0, 0, 0.6);
  
      &--error {
        color: red;
      }
  
      &:before {
        display: inline-block;
        width: 0;
        height: 16px;
        content: '';
        vertical-align: 0;
      }
    }
  
    &__datepicker {
      overflow: visible;
    }
  }
  
  /* Input, Textarea */
  
  .dgr-textfield-outlined > textarea {
    text-transform: uppercase;
    box-sizing: border-box;
    margin: 0;
    border: solid 1px;
    /* Safari */
    border-color: rgba(0, 0, 0, 0.6);
    border-top-color: transparent;
    border-radius: 4px;
    padding: 10px 13px 10px;
    width: 100%;
    height: inherit;
    color: rgba(0, 0, 0, 0.87);
    background-color: transparent;
    box-shadow: none;
    /* Firefox */
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    caret-color: rgb(28, 124, 213);
    transition: border 0.2s, box-shadow 0.2s;
  }
  
  /* Span */
  
  .dgr-textfield-outlined > textarea + span {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    border-color: rgba(0, 0, 0, 0.6);
    width: 100%;
    max-height: 100%;
    color: rgba(0, 0, 0, 0.6);
    font-size: 75%;
    line-height: 15px;
    cursor: text;
    transition: color 0.2s, font-size 0.2s, line-height 0.2s;
  }
  
  /* Corners */
  
  .dgr-textfield-outlined > textarea + span::before,
  .dgr-textfield-outlined > textarea + span::after {
    content: '';
    display: block;
    box-sizing: border-box;
    margin-top: 6px;
    border-top: solid 1px;
    border-top-color: rgba(0, 0, 0, 0.6);
    min-width: 10px;
    height: 8px;
    pointer-events: none;
    box-shadow: inset 0 1px transparent;
    transition: border-color 0.2s, box-shadow 0.2s;
  }
  
  .dgr-textfield-outlined > textarea + span::before {
    margin-right: 4px;
    border-left: solid 1px transparent;
    border-radius: 4px 0;
  }
  
  .dgr-textfield-outlined > textarea + span::after {
    flex-grow: 1;
    margin-left: 4px;
    border-right: solid 1px transparent;
    border-radius: 0 4px;
  }
  
  /* Hover */
  
  .dgr-textfield-outlined:hover > textarea {
    border-color: rgba(0, 0, 0, 0.87);
    border-top-color: transparent;
  }
  
  .dgr-textfield-outlined:hover > textarea + span::before,
  .dgr-textfield-outlined:hover > textarea + span::after {
    border-top-color: rgba(0, 0, 0, 0.87);
  }
  
  .dgr-textfield-outlined:hover > textarea:not(:focus):placeholder-shown {
    border-color: rgba(0, 0, 0, 0.87);
  }
  
  /* Placeholder-shown */
  
  .dgr-textfield-outlined > textarea:not(:focus):placeholder-shown {
    border-top-color: rgba(0, 0, 0, 0.6);
  }
  
  .dgr-textfield-outlined > textarea:not(:focus):placeholder-shown + span {
    font-size: inherit;
    line-height: 62px !important;
  }
  
  .dgr-textfield-outlined > textarea:not(:focus):placeholder-shown + span::before,
  .dgr-textfield-outlined > textarea:not(:focus):placeholder-shown + span::after {
    border-top-color: transparent;
  }
  
  /* Focus */
  
  .dgr-textfield-outlined > textarea:focus {
    border-color: rgb(28, 124, 213);
    border-top-color: transparent;
    box-shadow: inset 1px 0 inset -1px 0 #2196f3, inset 0 -1px #2196f3;
    outline: none;
  }
  
  .dgr-textfield-outlined > textarea:focus + span {
    color: rgb(28, 124, 213);
  }

  .dgr-textfield-outlined > textarea:focus + span::before,
  .dgr-textfield-outlined > textarea:focus + span::after {
    border-top-color: #2196f3 !important;
    /* box-shadow: inset 0 1px #2196f3;*/
  }
  
  /* Disabled */
  
  .dgr-textfield-outlined > textarea:disabled,
  .dgr-textfield-outlined > textarea:disabled + span {
    border-color: rgba(0, 0, 0, 0.38) !important;
    border-top-color: transparent !important;
    color: rgba(0, 0, 0, 0.38);
    pointer-events: none;
  }
  
  .dgr-textfield-outlined > textarea:disabled + span::before,
  .dgr-textfield-outlined > textarea:disabled + span::after {
    border-top-color: rgba(0, 0, 0, 0.38) !important;
  }
  
  .dgr-textfield-outlined > textarea:disabled:placeholder-shown,
  .dgr-textfield-outlined > textarea:disabled:placeholder-shown + span {
    border-top-color: rgba(0, 0, 0, 0.38) !important;
  }
  
  .dgr-textfield-outlined > textarea:disabled:placeholder-shown + span::before,
  .dgr-textfield-outlined > textarea:disabled:placeholder-shown + span::after {
    border-top-color: transparent !important;
  }
  

  .dgr-textfield-outlined > textarea + span::before {
    margin-right: 4px;
    border-left: solid 1px transparent;
    border-radius: 4px 0;
  }
  
  
  .dgr-textfield-outlined > textarea + span::after {
    flex-grow: 1;
    margin-left: 4px;
    border-right: solid 1px transparent;
    border-radius: 0 4px;
  }
  
  /* Hover */
  .dgr-textfield-outlined:hover > textarea {
    border-color: rgba(0, 0, 0, 0.87);
    border-top-color: transparent;
  }
  
  .dgr-textfield-outlined:hover > textarea + span::before,
  .dgr-textfield-outlined:hover > textarea + span::after {
    border-top-color: rgba(0, 0, 0, 0.87);
  }
  
  .dgr-textfield-outlined:hover > textarea:not(:focus):placeholder-shown {
    border-color: rgba(0, 0, 0, 0.87);
  }
  
  /* Placeholder-shown */
  .dgr-textfield-outlined > textarea:not(:focus):placeholder-shown {
    border-top-color: rgba(0, 0, 0, 0.6);
  }
  
  .dgr-textfield-outlined > textarea:not(:focus):placeholder-shown + span {
    font-size: inherit;
    line-height: 62px;
  }
  
  .dgr-textfield-outlined > textarea:not(:focus):placeholder-shown + span::before,
  .dgr-textfield-outlined > textarea:not(:focus):placeholder-shown + span::after {
    border-top-color: transparent;
  }
  
  /* Focus */

  .dgr-textfield-outlined > textarea:focus {
    border-color: rgb(28, 124, 213);
    border-top-color: transparent;
    box-shadow: inset 1px 0 inset -1px 0 #2196f3, inset 0 -1px #2196f3;
    outline: none;
  }
  
  .dgr-textfield-outlined > textarea:focus + span {
    color: rgb(28, 124, 213);
  }
  

  .dgr-textfield-outlined > textarea:focus + span::before,
  .dgr-textfield-outlined > textarea:focus + span::after {
    border-top-color: #2196f3 !important;
  }
  
  /* Disabled */
  .dgr-textfield-outlined > textarea:disabled,
  .dgr-textfield-outlined > textarea:disabled + span {
    border-color: rgba(0, 0, 0, 0.38) !important;
    border-top-color: transparent !important;
    color: rgba(0, 0, 0, 0.38);
    pointer-events: none;
  }
  
  .dgr-textfield-outlined > textarea:disabled + span::before,
  .dgr-textfield-outlined > textarea:disabled + span::after {
    border-top-color: rgba(0, 0, 0, 0.38) !important;
  }
  
  .dgr-textfield-outlined > textarea:disabled:placeholder-shown,
  .dgr-textfield-outlined > textarea:disabled:placeholder-shown + span {
    border-top-color: rgba(0, 0, 0, 0.38) !important;
  }
  
  .dgr-textfield-outlined > textarea:disabled:placeholder-shown + span::before,
  .dgr-textfield-outlined > textarea:disabled:placeholder-shown + span::after {
    border-top-color: transparent !important;
  }
  
  /* Faster transition in Safari for less noticable fractional font-size issue */
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
      .dgr-textfield-outlined > textarea,
      .dgr-textfield-outlined > textarea + span,
      .dgr-textfield-outlined > textarea + span::before,
      .dgr-textfield-outlined > textarea + span::after {
        transition-duration: 0.1s;
      }
    }
  }
  